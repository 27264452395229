import React from 'react';
import StandardBlockModel from './Models/StandardBlockModel.interface';
import { styled, theme } from '../Theme';
import GridItem from '../../Features/GridItem/GridItem';
import { StyleOrStyleArray } from '@glitz/type';
import { media, pseudo } from '@glitz/core';
import { linkColor, primaryBlue, transparent } from '../Theme/colors';
import KexLink from '../Shared/KexLink/KexLink';

type PropType = {
  content: StandardBlockModel;
};

function StandardBlock({
  content: { heading, subTitle, image, bodyText, link, layout, imageRight },
}: PropType) {
  const imageAreaBackgroundStyle: StyleOrStyleArray = {
    backgroundImage: image && `url(${image.src})`,
  };

  const { text: linkText, href = '' } = link || {};

  return (
    <GridItem layout={layout}>
      <Root data-blocklayout={layout}>
        <ImageArea
          css={imageAreaBackgroundStyle}
          data-imgright={imageRight}
          data-blocklayout={layout}
        >
          {!!href.length && <ImageLink href={href} />}
        </ImageArea>
        <TextContainer data-imgright={imageRight}>
          <div>
            <TitleText>{subTitle}</TitleText>
            <Heading>{heading}</Heading>
            <BodyText
              dangerouslySetInnerHTML={{
                __html: bodyText,
              }}
            />
            {link && (
              <LinkWrapper>
                <StyledKexLink href={link.href}>{link.text}</StyledKexLink>
              </LinkWrapper>
            )}
          </div>
        </TextContainer>
      </Root>
    </GridItem>
  );
}

export default StandardBlock;

const Root = styled.div({
  flexWrap: 'wrap',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: { x: '7.5px' },
  backgroundColor: transparent,
  ...media(theme.mediaQuery.mediaMinMedium, {
    flexWrap: 'nowrap',
  }),
  ...media(theme.mediaQuery.mediaMaxLarge, {
    paddingBottom: '24px',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    minHeight: '465px',
  }),
});

const ImageArea = styled.div({
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: '50% 50%',
  position: 'relative',
  order: 0,
  filter: theme.shadows.imageShadow,
  minHeight: theme.bannerBlockMobileImageHeight,
  ...media(theme.mediaQuery.mediaMinMedium, {
    flex: { grow: 0, shrink: 0, basis: '49%' },
    ...pseudo([':nth-child(n)[data-imgright="true"]'], {
      ...media(theme.mediaQuery.mediaMinMedium, {
        order: 1,
      }),
    }),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    minHeight: theme.bannerBlockDesktopImageHeight,
  }),
});

const ImageLink = styled.a({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const TextContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  ...media(theme.mediaQuery.mediaMinMedium, {
    padding: { y: '30px' },
    ...pseudo([':nth-child(n)[data-imgright="false"]'], {
      paddingLeft: '35px',
      ...media(theme.mediaQuery.mediaMaxHuge, {
        paddingRight: '15px',
      }),
    }),
    ...pseudo([':nth-child(n)[data-imgright="true"]'], {
      paddingRight: '35px',
      ...media(theme.mediaQuery.mediaMinMedium, {
        paddingLeft: '15px',
      }),
      ...media(theme.mediaQuery.mediaMinHuge, {
        paddingLeft: 0,
      }),
    }),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    ...pseudo([':nth-child(n)[data-imgright="false"]'], {
      paddingLeft: '70px',
    }),
    ...pseudo([':nth-child(n)[data-imgright="true"]'], {
      paddingRight: '70px',
    }),
  }),
});

const TitleText = styled.p({
  font: { size: theme.large, weight: theme.fontWeight.normal },
  lineHeight: theme.lineHeight.moreRelaxed,
  marginBottom: '8px',
  color: primaryBlue,
  textTransform: 'uppercase',
  ...media(theme.mediaQuery.mediaMinMedium, {
    fontSize: theme.subTitle,
    marginBottom: '10px',
  }),
  ...media(theme.mediaQuery.mediaMaxMedium, {
    marginTop: '30px',
  }),
});

const Heading = styled.h2({
  font: { size: theme.nearGreat, weight: theme.fontWeight.normal },
  lineHeight: '42px',
  marginBottom: '20px',
  color: primaryBlue,
  ...media(theme.mediaQuery.mediaMinMedium, {
    fontSize: theme.headingDesktop,
    marginBottom: theme.huge,
  }),
});

const BodyText = styled.div({
  marginBottom: '32px',
  lineHeight: '32px',
  ...media(theme.mediaQuery.mediaMinSmall, {
    lineHeight: '36px',
  }),
});

const LinkWrapper = styled.div({
  width: 'fit-content',
});

const StyledKexLink = styled(KexLink, {
  color: linkColor,
  textDecoration: 'underline',
});
