import React from 'react';
import { styled, theme } from '../Theme';
import { media, pseudo } from '@glitz/core';

type PropType = {
  layout?: string;
  children: JSX.Element | string;
};

function GridItem({ layout = '1/1', children }: PropType) {
  return (
    <GridItemContainer data-gridsize={layout}>{children}</GridItemContainer>
  );
}

const GridItemContainer = styled.div({
  width: '100%',
  height: '100%',
  margin: { x: 'auto' },
  gridColumnStart: 'auto',
  gridColumnEnd: 'span 12',
  maxWidth: theme.blockMaxWidthMobile,
  ...media(theme.mediaQuery.mediaMinMedium, {
    maxWidth: 'unset',
    ...pseudo(
      [
        ':nth-child(n)[data-gridsize="1/2"]',
        ':nth-child(n)[data-gridsize="1/3"]',
        ':nth-child(n)[data-gridsize="1/4"]',
      ],
      {
        gridColumnEnd: 'span 6',
      }
    ),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    ...pseudo([':nth-child(n)[data-gridsize="1/3"]'], {
      gridColumnEnd: 'span 4',
    }),
    ...pseudo([':nth-child(n)[data-gridsize="1/4"]'], {
      gridColumnEnd: 'span 3',
    }),
    margin: { x: theme.none },
  }),
});

export default GridItem;
